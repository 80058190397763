import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ResponseBackAzure } from '@core/models/responsehttp.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InspecionesService {
  private readonly _http = inject(HttpClient);

  httpParam = new HttpParams().append('backend', 'azure');

  getTalleres() {
    return this._http.get<ResponseBackAzure>(`${environment.WebApiUrlCarantyamdev}/citas/catalogo/talleres`, {
      params: this.httpParam,
    });
  }

  getPagosInspeccion(filterIdCita?: string) {
    return this._http.get<ResponseBackAzure>(
      `${environment.WebApiUrlCarantyamdev}/citas/pagos/inspecciones?id_cita=${filterIdCita ?? ''}`,
      {
        params: this.httpParam,
      }
    );
  }

  postSolicitarPago(body: any) {
    return this._http.post<ResponseBackAzure>(`${environment.WebApiUrlCarantyamdev}/citas/pagos/inspecciones`, body, {
      params: this.httpParam,
    });
  }

  putPagoInspeccion(body: any) {
    return this._http.put<ResponseBackAzure>(`${environment.WebApiUrlCarantyamdev}/citas/pagos/inspecciones`, body, {
      params: this.httpParam,
    });
  }
}
